import { SkeletonText } from '@chakra-ui/react'
import * as yup from 'yup'
import SearchIcon from './svgs/search.svg'
import React from 'react'
import { constructLookupData, LookupData } from './lookup-data.utils'
import styles from './InteractiveLookup.module.scss'
import { Error } from 'components/common/Texts/Error/Error'
import Input from 'components/common/Input/Input'
import {
  ListItemWithTooltip,
  ListItemContent,
  ListItemWithoutTooltip,
} from './ListItems'
import { getRootDomain } from 'utils/parseUrl'
import { domainEmptyError, domainInvalidError } from 'constants/errors'
import { domainRegex } from 'constants/regex'
import { Form, Formik } from 'formik'
import classNames from 'classnames'
import Recaptcha from 'components/common/Recaptcha/Recaptcha'
import { useAppraiseDomain } from 'hooks/useAppraise'


export default function InteractiveLookup() {
  const [lookupData, setLookupData] = React.useState<LookupData[]>([])
  const [fullLookupData, setFullLookupData] = React.useState<LookupData[]>([])
  const [hasScrolled, setHasScrolled] = React.useState(false)
  const [showRecaptcha, setShowRecaptcha] = React.useState(false);
  const [domain, setDomain] = React.useState<string>('')

  const recaptchaRef = React.useRef(null);
  const { mutate, error, isLoading } = useAppraiseDomain();


  const initialData = React.useCallback(async () => {
    const fullLookupData = await constructLookupData()
    setFullLookupData(fullLookupData)
    setLookupData(hasScrolled ? fullLookupData : fullLookupData.splice(0, 10))
  }, [hasScrolled])

  React.useEffect(() => {
    if (domain === '') {
      initialData()
    }
  }, [initialData, domain])

  const DomainValidationSchema = yup
    .object()
    .noUnknown()
    .shape({
      domain: yup
        .string()
        .trim()
        .required(domainEmptyError)
        .test('cleanDomainCheck', domainInvalidError, (value) => {
          return domainRegex.test(getCleanedDomain(value)) ?? false
        }),
      g_recaptcha_response: yup.string().required(''),
    })

  const getCleanedDomain = (domain: string) => {
    if (!domain) {
      return ''
    }
    return getRootDomain(domain.replaceAll(' ', ''))
  }

  const handleSubmit = async ({ domain, g_recaptcha_response }, { setFieldValue }) => {
    try {
      setShowRecaptcha(false);
      setDomain(domain)
      const data = await mutate({ domain, g_recaptcha_response });
      const constructedLookupData = await constructLookupData(data?.data)
      const partialData = constructedLookupData.slice(0);
      setFullLookupData(constructedLookupData)
      setLookupData(hasScrolled ? constructedLookupData : partialData.splice(0, 10))
    } catch (e) {
      //
    } finally {
      setFieldValue('g_recaptcha_response', '');
      recaptchaRef?.current?.reset()
    }
  };

  const errorObj = error?.response?.data

  return (
    <div className={styles.box}>
      <Formik
        validationSchema={DomainValidationSchema}
        onSubmit={handleSubmit}
        validateOnChange={false}
        validateOnBlur={true}
        initialValues={{
          domain: '',
          g_recaptcha_response: ''
        }}
      >
        {({ setFieldValue, submitForm }) => (
          <Form>

            <div className={styles.inputSearchWrap}>
              <div className={styles.inputWrap}>
                <Input
                  name="domain"
                  placeholder="Check a domain e.g domain.com"
                  className={styles.input}
                  inputErrorClassName={styles.inputError}
                />
                <SearchIcon className={styles.icon} />
              </div>
              <button
                // type="submit"
                className={
                  isLoading ? styles.checkButtonDisabled : styles.checkButton
                }
                onClick={(e) => {
                  e.preventDefault()
                  setShowRecaptcha(true)
                }}
                disabled={isLoading}
              >
                Check
              </button>
            </div>




            <div
              className={styles.lookupListWrap}
              onScroll={(e: any) => {
                if (e.target.scrollTop > 0 && !hasScrolled) {
                  setHasScrolled(true)
                  setLookupData(fullLookupData)
                }
              }}
            >
              {showRecaptcha && (
                <div className={styles.recaptchaContainer}>
                  <Recaptcha
                    ref={recaptchaRef}
                    name="g_recaptcha_response"
                    verifyCallback={(response) => {
                      setFieldValue('g_recaptcha_response', response)
                      setTimeout(() => {
                        submitForm()
                      }, 0)
                    }
                    }
                  />
                </div>
              )}
              {!showRecaptcha && <div className={styles.lookupList}>
                <ul
                  className={classNames(
                    styles.listContent,
                    isLoading && styles.loading
                  )}
                >
                  {isLoading ? (
                    <SkeletonText noOfLines={14} spacing="4" height="10" />
                  ) : error ? (
                    <Error>Error: {errorObj?.message || 'Unknown'}</Error>
                  ) : (
                    lookupData.map((item) => {
                      if (item?.children) {
                        return (
                          <li className={styles.subItemWrap} key={item.name}>
                            <ListItemWithoutTooltip>
                              <ListItemContent name={item.name} />
                            </ListItemWithoutTooltip>

                            <ul className={styles.subItem}>
                              {item.children.map((child) => (
                                <ListItemWithTooltip
                                  key={`${child.name}-${Math.random()}`}
                                  label={child.label}
                                  iconFill="#046dc1"
                                >
                                  <ListItemContent
                                    name={child.name}
                                    value={child.value}
                                  />
                                </ListItemWithTooltip>
                              ))}
                            </ul>
                          </li>
                        )
                      }

                      return (
                        <ListItemWithTooltip key={item.name} label={item.label}>
                          <ListItemContent name={item.name} value={item.value} />
                        </ListItemWithTooltip>
                      )
                    })
                  )}
                </ul>
              </div>}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}
