import { parseUrl as qsParseUrl, ParseOptions, ParsedUrl } from 'query-string'
import psl from 'psl'

interface ParsedUrlWithQuery<T extends string, K extends string | undefined>
  extends ParsedUrl {
  query: {
    [key in T]: K
  }
}

export function parseUrl<T extends string, K extends string | undefined>(
  url: string,
  options?: ParseOptions
): ParsedUrlWithQuery<T, K> {
  return qsParseUrl(url, options) as ParsedUrlWithQuery<T, K>
}

/**
 * Returns the domain name from a URL
 *
 * "https://google.com/params?foo=bar" -> "google.com"
 * "www.google.com"                    -> "google.com"
 * "http://dev.google.com"             -> "google.com"
 * "https://google.co.in"              -> "google.co.in"
 * "https://dev.google.co.in"          -> "google.co.in"
 *
 * @param url
 */
export function getRootDomain(url: string) {
  if (!url) return url
  const matches = url.match(/(^https?\:?\/\/)?([^\/:?#]+)(?:[\/:?#]|$)/i)
  const cleanedUrl = matches && matches[2]
  const parsedUrl = psl.parse(cleanedUrl)
  const domain = parsedUrl.domain
  return domain ?? url
}
