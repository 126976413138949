export const fullNameRegex = /(\s)/
export const fullNameUrlRegex = new RegExp("([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?")

export const passwordSpecialCharacterRegex = /(?=.*[_!@#$%^&*-])/
export const passwordNumberRegex = /(?=.*\d)/
export const passwordUpperCaseRegex = /(?=.*[A-Z])/
export const passwordLowerCaseRegex = /(?=.*[a-z])/

export const domainRegex =
  /^((?=[a-z0-9-]{1,63}\.)(xn--)?[a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,63}$/i
export const extensionRegex = /^[a-zA-Z\.]*$/
