import React from 'react'

import styles from './ListItems.module.scss'

interface Props {
  label?: string
  iconFill?: string
}

// eslint-disable-next-line react/display-name
export const ListItemWithTooltip: React.FC<Props> = React.forwardRef<
  any,
  Props
>(({ children, label, iconFill = '#0AC12D', ...props }, ref) => {
  return (
    <li className={styles.toolTipWrap}>
      <div className={styles.listItem} ref={ref} {...props}>
        <ListItemIcon iconFill={iconFill} />
        {children}
      </div>
      <span className={styles.toolTip}>{label}</span>
    </li>
  )
})

export const ListItemWithoutTooltip: React.FC<Props> = React.forwardRef<
  any,
  Props
>(({ children, label, iconFill = '#0AC12D', ...props }, ref) => {
  return (
    <div className={styles.listItem} ref={ref} {...props}>
      <ListItemIcon iconFill={iconFill} />
      {children}
    </div>
  )
})

export const ListItemIcon: React.FC<Props> = React.forwardRef<any, Props>(
  ({ children, label, iconFill = '#0AC12D', ...props }, ref) => {
    return (
      <div className={styles.image}>
        <svg width={24} height={24} {...props} viewBox="0 0 24 24" ref={ref}>
          <rect width={24} height={24} rx={2.521} fill={iconFill} />
          <path
            d="m15.872 8.522.816.72c-.523.459-.95.901-1.28 1.328-.32.416-.523.73-.608.944-.086.213-.166.47-.24.768l.064.096c.714 0 1.237.155 1.568.464.33.31.496.79.496 1.44 0 .502-.181.955-.544 1.36a1.685 1.685 0 0 1-1.328.608c-.608 0-1.115-.17-1.52-.511-.406-.342-.608-.891-.608-1.649 0-1.002.309-2.037.928-3.104.63-1.077 1.381-1.899 2.256-2.464Zm-5.937 0 .816.72a9.489 9.489 0 0 0-1.264 1.312c-.32.416-.528.736-.624.96-.085.224-.165.48-.24.768l.064.096c.715 0 1.238.155 1.568.464.331.31.496.79.496 1.44 0 .502-.181.955-.544 1.36a1.685 1.685 0 0 1-1.328.608c-.608 0-1.115-.17-1.52-.511-.405-.342-.608-.891-.608-1.649 0-1.002.31-2.037.928-3.104.63-1.077 1.382-1.899 2.256-2.464Z"
            fill="#fff"
          />
        </svg>
      </div>
    )
  }
)

interface ListItemContentProps {
  name: string
  value?: string
}

export const ListItemContent: React.FC<ListItemContentProps> = ({
  name,
  value,
}) => {
  return (
    <div className={styles.row}>
      <span className={styles.key}>{name}:</span>
      {name != 'Similar Sales' && (
        <span className={styles.value}>{`"${value ?? 'N/A'}"`}</span>
      )}
    </div>
  )
}
