import { AxiosResponse } from 'axios';
import { LookupResponse } from 'models/lookup-response.interface';
import { useMutation } from 'react-query';
import { domainService } from 'services/domains.service';

interface FormValues {
    domain: string;
    g_recaptcha_response: string;
}

interface UseAppraiseDomainResult {
    mutate: (values: FormValues) => Promise<AxiosResponse<LookupResponse>>;
    error: any;
    isLoading: boolean;
}

export const useAppraiseDomain = (): UseAppraiseDomainResult => {
    const mutation = useMutation<AxiosResponse<LookupResponse>, unknown, FormValues>(
        ({ domain, g_recaptcha_response }: FormValues) => {
            return domainService.appraisePost(domain, g_recaptcha_response);
        }
    );

    const mutate: UseAppraiseDomainResult['mutate'] = async (values: FormValues) => {
        return mutation.mutateAsync(values);
    };

    return {
        mutate,
        error: mutation.error,
        isLoading: mutation.isLoading,
    };
};